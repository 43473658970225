import React from "react"
import { HStack, VStack, Box, Heading, Button, Text } from "@chakra-ui/react"

import { useCustomerContext } from "@app/providers/customer"
import { useCustomerLogout } from "@app/hooks/useCustomer"
import { useShopify } from "@app/hooks/useShopify"
import { Link } from "gatsby"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"

const CustomerDashboardCard: React.FC = () => {
  const { customer } = useCustomerContext()
  const { logoutCustomer } = useCustomerLogout()
  const { edgeNormaliser } = useShopify()
  const { urlResolver } = useRoutes()
  const {
    settings: { routes },
  } = useConfigContext()
  const defaultAddress = edgeNormaliser(customer.addresses).find(address => address?.id === customer?.defaultAddress?.id)
  return (
    <Box p="4" bg="grey.100" w="full" mb={4}>
      <HStack justify="space-between" mb={4}>
        <Heading size="h3" as="h3">
          {customer?.firstName} {customer?.lastName}
        </Heading>
        <Button
          variant="ghost"
          textTransform="none"
          size="sm"
          fontWeight="normal"
          letterSpacing="0"
          fontSize="14px"
          px="0"
          textDecoration="underline"
          onClick={() => logoutCustomer()}
        >
          Log out
        </Button>
      </HStack>
      <VStack align="start" mb={4}>
        {defaultAddress?.phone?.length > 0 && <Text size="14">Phone: {defaultAddress.phone}</Text>}
        <Text size="14">Email: {customer.email}</Text>
        {defaultAddress?.address1?.length > 0 && (
          <Text size="14">{`Address: ${defaultAddress.address1}, ${defaultAddress.city}, ${defaultAddress.province},
              ${defaultAddress.zip}`}</Text>
        )}
        {defaultAddress?.country?.length > 0 && <Text size="14">{`Country: ${defaultAddress.country}`}</Text>}
      </VStack>
      <Button
        variant="ghost"
        textTransform="none"
        size="sm"
        fontWeight="normal"
        letterSpacing="0"
        fontSize="14px"
        px="0"
        textDecoration="underline"
        as={Link}
        to={urlResolver({ title: "Addresses" }, routes.ADDRESSES).url}
      >
        {`View Addresses (${edgeNormaliser(customer.addresses).length})`}
      </Button>
    </Box>
  )
}

export default React.memo(CustomerDashboardCard)
