import React, { useEffect } from "react"
import type { Props } from "@app/pages/account"
import type { PageProps } from "@root/types/global"
import CustomerDashboardCard from "./CustomerDashboardCard"
import CustomerLayout from "../Layout/CustomerLayout"
import { Box, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, Text } from "@chakra-ui/react"
import CustomerOrdersCard from "../Orders/CustomerOrdersCard"
import { useCustomerContext } from "@app/providers/customer"
import { useCore } from "@app/hooks/useCore"
import { useShop } from "@app/hooks/useShop"
import { useCheckoutContext } from "@app/providers/checkout"
import { useConfigContext } from "@app/providers/config"

const CustomerDashboard: React.FC<PageProps<Props>> = ({ page, location }) => {
  const { customer } = useCustomerContext()
  const { gotoCheckout } = useCheckoutContext()
  const { stores } = useConfigContext()
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { shop } = useShop()

  const currentStore = Object.values(stores)?.find(({ shopName }) => shop?.myshopifyDomain.includes(shopName))

  useEffect(() => {
    if (location?.state?.checkout && customer?.id) {
      gotoCheckout()
    }
  }, [customer, location, gotoCheckout])

  useEffect(() => {
    if (!location?.state?.checkout && customer?.id && currentStore?.loyaltyEndpoint) {
      const id = decodeShopifyId(customer?.id, "Customer")
      fetch(currentStore.loyaltyEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ shopify_id: id }),
      })
    }
  }, [currentStore, customer, location, decodeShopifyId])

  return (
    <>
      <CustomerLayout title={page?.title}>
        <Box>
          <CustomerDashboardCard />
          <CustomerOrdersCard />
        </Box>
      </CustomerLayout>
      <Modal
        closeOnOverlayClick={false}
        isOpen={location?.state?.checkout}
        motionPreset="slideInBottom"
        size="lg"
        isCentered
        onClose={() => null}
      >
        <ModalOverlay />
        <ModalContent textAlign="center" p={[8, 16]} borderRadius="none" w={["90vw", "auto"]}>
          <ModalBody>
            <Text pb={8}>Returning to checkout...</Text>
            <Spinner />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CustomerDashboard
